import React, { useCallback } from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";

import theme from "../theme";

import { ChakraProvider } from "@chakra-ui/react";

import { Flex, Text, Stack, Box, SimpleGrid, VStack } from "@chakra-ui/react";

import { FiMail } from "react-icons/fi";
import {
  HiOutlineLocationMarker,
  HiOutlinePhone,
  HiOutlineMail,
} from "react-icons/hi";

import contact from "../images/contact.jpg";

import GlobalStyle from "../globalStyles";

const Kontakt = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DMS-VID - Svet pre vaše auto :: Kontakt</title>
      </Helmet>

      <GlobalStyle />

      <ChakraProvider theme={theme}>
        <Navbar
          home="whiteAlpha.600"
          oils="whiteAlpha.600"
          accessories="whiteAlpha.600"
          contact="red.500"
        />

        <Flex
          w={"full"}
          backgroundImage={contact}
          backgroundSize={"cover"}
          backgroundPosition={"0px -17rem"}
          backgroundRepeat="no-repeat"
          backgroundAttachment="fixed"
        >
          <VStack
            w={"full"}
            justify={"center"}
            bgGradient={"linear(to-b, blackAlpha.700, blackAlpha.600)"}
            py={{ base: 12, md: 16, lg: 20, xl: 24 }}
          >
            <Stack maxW={"8xl"} align={"center"}>
              <Text
                color={"white"}
                fontWeight={800}
                lineHeight={1.2}
                fontSize={{ base: "4xl", md: "5xl", lg: "6xl", xl: "7xl" }}
                align="center"
                mt={20}
              >
                <Flex alignItems="center">
                  <Box mr={2} color="red.500">
                    <FiMail />
                  </Box>{" "}
                  KONTAKT
                </Flex>
              </Text>
            </Stack>
          </VStack>
        </Flex>

        <Flex
          px={{
            base: "24px",
            md: "48px",
            lg: "96px",
            "2xl": "256px",
          }}
          py={{ base: 12, xl: 24 }}
          bg="white"
          transition="all 250ms"
          flexWrap="wrap"
          flexDirection="column"
        >
          <SimpleGrid
            columns={{ base: "1", md: "2", xl: "4" }}
            spacing={16}
            mb={12}
          >
            <Flex align="center" justify="center">
              <Box
                fontSize={{ base: "36", md: "24", xl: "36" }}
                p={{ base: "3", md: "2", xl: "3" }}
                borderRadius="full"
                mr={4}
                color="red.500"
                bg="red.50"
              >
                <HiOutlineMail />
              </Box>
              <Box>
                <Text fontSize="lg" fontWeight={600}>
                  info@dms-vid.sk
                </Text>
              </Box>
            </Flex>
            <Flex align="center" justify="center">
              <Box
                fontSize={{ base: "36", md: "28", xl: "36" }}
                p={{ base: "3", md: "2", xl: "3" }}
                borderRadius="full"
                mr={4}
                color="red.500"
                bg="red.50"
              >
                <HiOutlinePhone />
              </Box>
              <Box>
                <Text fontSize="lg" fontWeight={600}>
                  +421 944 508 983
                </Text>
              </Box>
            </Flex>
            <Flex align="center" justify="center">
              <Box
                fontSize={{ base: "36", md: "28", xl: "36" }}
                p={{ base: "3", md: "2", xl: "3" }}
                borderRadius="full"
                mr={4}
                color="red.500"
                bg="red.50"
              >
                <HiOutlinePhone />
              </Box>
              <Box>
                <Text fontSize="lg" fontWeight={600}>
                  +421 911 933 575
                </Text>
              </Box>
            </Flex>
            <Flex align="center" justify="center">
              <Box
                fontSize={{ base: "36", md: "28", xl: "36" }}
                p={{ base: "3", md: "2", xl: "3" }}
                borderRadius="full"
                mr={4}
                color="red.500"
                bg="red.50"
              >
                <HiOutlinePhone />
              </Box>
              <Box>
                <Text fontSize="lg" fontWeight={600}>
                  +421 915 345 288
                </Text>
              </Box>
            </Flex>
          </SimpleGrid>

          <SimpleGrid
            columns={{ base: "1", md: "2", xl: "4" }}
            spacing={16}
            mb={{ base: 12, xl: 16 }}
          >
            <Flex align="center" justify="center">
              <Box
                fontSize={{ base: "36", md: "28", xl: "36" }}
                p={{ base: "3", md: "2", xl: "3" }}
                borderRadius="full"
                mr={4}
                color="red.500"
                bg="red.50"
              >
                <HiOutlineLocationMarker />
              </Box>
              <Box>
                <Text fontSize="lg" fontWeight={600}>
                  Prešovská 84,
                </Text>
                <Text fontWeight={600}>082 12 Kapušany</Text>
              </Box>
            </Flex>
            <Flex align="center" justify="center">
              <Box
                fontSize={{ base: "20", md: "16", xl: "20" }}
                fontWeight={700}
                p={{ base: "4", md: "3", xl: "4" }}
                borderRadius="full"
                mr={4}
                color="red.500"
                bg="red.50"
              >
                IČO
              </Box>
              <Box>
                <Text fontSize="lg" fontWeight={600}>
                  53541707
                </Text>
              </Box>
            </Flex>
            <Flex align="center" justify="center">
              <Box
                fontSize={{ base: "20", md: "16", xl: "20" }}
                fontWeight={700}
                p={{ base: "4", md: "3", xl: "4" }}
                borderRadius="full"
                mr={4}
                color="red.500"
                bg="red.50"
                textAlign="center"
              >
                IČ DPH
              </Box>
              <Box>
                <Text fontSize="lg" fontWeight={600}>
                  SK2121399720
                </Text>
              </Box>
            </Flex>
            <Flex align="center" justify="center">
              <Box
                fontSize={{ base: "20", md: "16", xl: "20" }}
                fontWeight={700}
                p={{ base: "4", md: "3", xl: "4" }}
                borderRadius="full"
                mr={4}
                color="red.500"
                bg="red.50"
              >
                DIČ
              </Box>
              <Box>
                <Text fontSize="lg" fontWeight={600}>
                  2121399720
                </Text>
              </Box>
            </Flex>
          </SimpleGrid>
          <Flex align="center" justify="center">
            <iframe
              width="100%"
              height={500}
              style={{ border: "0", frameborder: "0" }}
              loading="lazy"
              allowFullScreen
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBNuxBVM6VUztT0oIJZ_oC3yf3sEXPiqpw&q=Presovska+84,Kapusany+Slovakia&zoom=12"
              title="Google Maps"
            ></iframe>
          </Flex>
        </Flex>

        <Footer />
      </ChakraProvider>
    </>
  );
};

export default Kontakt;
